html {
    scroll-behavior: smooth;
  }
  
  body {
    padding: 0;
    margin: 0;
  }
  
  label {
    text-align: left !important;
  }
  
  header .logo > img {
    width: 200px;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.5em;
    margin-bottom: 3em;
  }
  
  header.bg-curve-primary-up {
    background-color: #fa8e00 !important;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    height: 120px;
    z-index: -1000;
  }
  
  .border-b {
    border-bottom: 1px solid #fa8e00;
    padding-bottom: 0.5em;
  }
  
  header .dash {
    font-size: 2rem;
    color: white;
    margin: 0.5em;
    cursor: pointer;
  }
  
  header > nav {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  
  header > nav > a.nav-item {
    margin: 0.5em 1em;
    cursor: pointer;
  }
  
  .container {
    height: 100%;
  }
  
  .chart_container {
    width: 40%;
    margin: 2em auto;
  }
  
  .content-full, .content, .content-top {
    height: auto;
    min-height: calc(90vh - 2em);
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .content-top {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  
  .content-full {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .content-full .logo, .content-top .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .content .logo > img, .content-full .logo > img {
    height: auto;
    max-width: 300px;
    margin: 2em auto;
  }
  
  .content-full > div:nth-child(2), .content > div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 50%;
    margin: 0 auto;
  }
  
  .content-full > div.product-details, .content-top > div.product-details, .content > div.product-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
    padding: 1em;
    padding-top: 0;
  }
  
  .content-full > div {
    width: 50%;
  }
  
  .content-full > div.wide_form {
    width: 80%;
  }
  
  .medium-text {
    font-size: 1.5rem;
  }
  
  .large-text {
    font-size: 2rem;
  }
  
  .small-text {
    font-size: 0.5rem;
  }
  
  .text-right {
    text-align: right !important;
  }
  
  .text-left {
    text-align: left !important;
  }
  
  .text-center {
    text-align: center !important;
    align-self: center !important;
  }
  
  a,
  a:visited,
  a.nav-item,
  a.nav-item:visited {
    color: #045e13;
    text-decoration: none;
  }
  
  a:hover,
  a.nav-item:hover {
    font-weight: bold;
    color: #045e13;
  }
  
  .dashboard, .box {
    background-color: white !important;
    margin: -6em auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    width: 95%;
    min-height: calc(90vh - 4em);
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .product-details {
    border-left: 1px solid #045e13;
    font-size: medium;
  }
  
  .product-details > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1em;
  }
  
  .product-details label, .form-group > label {
    color: #312f2f;
    text-align: left !important;
  }
  
  .product-details span {
    color: #045e13;
    font-size: large;
  }
  
  .details, .details-list {
    flex-wrap: wrap;
  }
  
  .item-list-head, .item-list {
    width: 100%;
    padding: 0.5em 0;
    margin-top: 0.5em;
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: 100px;
  }
  
  .item-list-head > span, .item-list > div {
    border-right: 0.5px solid rgba(179, 184, 179, 0.7411764706);
    align-self: center;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 0 0.5em;
  }
  
  .item-list-head > span:last-child, .item-list > div:last-child {
    border-right: unset;
  }
  
  .item-list-head {
    border-left: 3px solid transparent;
  }
  
  .item-list {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .flag-red {
    border-left: 3px solid red !important;
  }
  
  .flag-ok {
    border-left: 3px solid #045e13;
  }
  
  .item-list:hover {
    background-color: rgba(156, 152, 152, 0.3215686275) !important;
  }
  
  .item-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 0.5em;
    flex-grow: 1;
  }
  
  .item-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0.5em;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 0.5em;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .item-title {
    font-size: 1.2rem;
  }
  
  .item-focus {
    color: #045e13;
  }
  
  .details {
    width: calc(50% - 1em);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    margin: 0.5em;
  }
  
  @media only screen and (max-width: 920px) {
    .details {
      width: calc(50% - 1em);
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
      margin: 0.5em;
    }
  }
  .details-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    align-items: flex-start !important;
  }
  
  .actions-wide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .graph {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50% !important;
  }
  
  .graph > canvas {
    width: 100%;
    height: auto;
  }
  
  img.image_viewer {
    display: block;
    margin: 2em auto;
    border: 1px solid #fa8e00;
    width: calc(70% - 4em);
    -o-object-fit: contain;
       object-fit: contain;
    align-self: center;
  }
  
  .toast {
    position: fixed;
    top: 0;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.596);
    padding: 2em;
    z-index: 10000000000000000000000000000000;
    height: 200px;
    display: block;
  }
  
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  
  .action-buttons > span {
    cursor: pointer;
    padding: 0.5em;
  }
  
  .action-buttons > span:hover {
    color: #045e13;
    background-color: rgba(250, 142, 0, 0.368627451);
    border-radius: 50%;
  }
  
  .icon-button {
    border-radius: 5px;
    box-shadow: 3px 3px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    color: #fa8e00;
    width: 120px;
    height: 120px;
    margin: 1em auto;
    cursor: pointer;
    border: 1px solid #fa8e00;
    flex: 0 0 auto;
  }
  
  .icon-button > span.material-icons {
    font-size: 3rem;
    cursor: pointer;
  }
  
  .icon-button:hover {
    background-color: #045e13 !important;
    color: white;
    border: 0px solid #fa8e00;
  }
  
  @media only screen and (max-width: 1130px) {
    .icon-button {
      border-radius: 5px;
      box-shadow: 3px 3px 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5em;
      color: #fa8e00;
      width: 100px;
      height: 100px;
      margin: 1em auto;
      cursor: pointer;
      border: 1px solid #fa8e00;
      flex: 0 0 auto;
    }
    .icon-button > span.material-icons {
      font-size: 2rem;
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 1131px) {
    .icon-button {
      border-radius: 5px;
      box-shadow: 3px 3px 3px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.5em;
      color: #fa8e00;
      width: 116px;
      height: 116px;
      margin: 1em auto;
      cursor: pointer;
      border: 1px solid #fa8e00;
      flex: 0 0 auto;
    }
    .icon-button > span.material-icons {
      font-size: 3rem;
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 1441px) {
    .icon-button {
      border-radius: 10px;
      box-shadow: 5px 5px 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2em;
      color: #fa8e00;
      width: 150px;
      height: 150px;
      margin: 2em auto;
      cursor: pointer;
      border: 2px solid #fa8e00;
      flex: 0 0 auto;
      font-size: 1.1rem;
    }
    .icon-button > span.material-icons {
      font-size: 3rem;
      cursor: pointer;
    }
  }
  .bg-success {
    background-color: #045e13 !important;
    color: white !important;
  }
  
  .primary-bg {
    background-color: #fa8e00 !important;
    color: white !important;
  }
  
  .feedback, .error {
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.534) !important;
    text-align: center;
  }
  
  .error {
    color: red;
    font-size: small;
  }
  
  .danger, .success {
    text-align: center;
    width: 100%;
    flex-grow: 3;
    color: white;
  }
  
  .danger {
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.37) !important;
  }
  
  .success {
    border: 1px solid #045e13;
    background-color: rgba(114, 231, 134, 0.631372549) !important;
    color: #045e13;
  }
  
  .spinner {
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #045e13;
    border-top: none;
    -webkit-animation: spin 1s linear 0s infinite forwards;
            animation: spin 1s linear 0s infinite forwards;
  }
  
  .hidden {
    display: none !important;
  }
  
  .clickable {
    cursor: pointer;
  }
  
  .clickable:hover {
    text-decoration: underline;
  }
  
  @-webkit-keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }/*# sourceMappingURL=main.css.map */