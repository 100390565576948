$primaryColor:#075a45 !important;
$primaryColorTrans: #7fc089a1  !important;
$secondaryColor: #fa8e00  !important;
$secondaryColorTrans:#fa8e005e !important;
$faint:#9c989852 !important;
$faintDark:#312f2f !important;
$defaultColor: white !important;
$defaultColorTrans: rgba(255, 255, 255, 0.534) !important;
$dangerColor:red !important;
$dangerColorTrans:rgba(255, 0, 0, 0.37) !important;

@mixin shade {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@mixin flex($d: row, $j: center, $a: center) {
  display: flex;
  flex-direction: $d;
  justify-content: $j;
  align-items: $a;
}
@mixin setBg($color: $defaultColor) {
  background-color: $color !important;
}

html {
  scroll-behavior: smooth;
}
body {
  padding: 0;
  margin: 0;
  // text-align: center;
}
label{
  text-align: left !important;
}

header .logo > img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
header {
  @include flex(row, space-between, flex-start);
  padding: 0.5em;
  margin-bottom: 3em;
}
header.bg-curve-primary-up {
  @include setBg($primaryColor);
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  height: 120px;
  z-index: -1000;
}
.border-b{
  border-bottom:1px solid $primaryColor;
  padding-bottom:0.5em;
}
header .dash{
  font-size: 2rem;color:$defaultColor;
  margin:0.5em;
  cursor:pointer;
}
header > nav{
  @include flex(row,space-evenly, center);
}
header > nav > a.nav-item{
  margin:0.5em 1em;
  cursor: pointer;
}
.container{
  height:100%;
}
.chart_container{
  width:30%;
  margin:2em auto;
}
.content-full,.content,.content-top {
  height: auto;
  min-height: calc(90vh - 2em);
  width: 100%;
  margin: 0 auto;
  padding:0;
}
.content{
  @include flex(row, space-between, center);}
.content-top{
  @include flex(row, space-evenly, flex-start);
  // height: 90vh;

}
.content-full{
  @include flex(row,center, center);
  height:100vh;
}
.content-full .logo,.content-top .logo {
  @include flex(column, center, center);
  height:100%;
  // border:1px solid red;
}

.content .logo > img,.content-full .logo > img {
  // width: calc(50% - 4em);
  height: auto;
  max-width: 300px;
  margin: 2em auto;
  // border: 1px solid blue;
}
.content-full > div:nth-child(2),.content > div:nth-child(2) {
  @include flex(row, space-evenly, center);
  height: 100%;
  width: 50%;
  margin:0 auto;
}
.content-full > div.product-details,.content-top > div.product-details,.content > div.product-details{
  @include flex(column,flex-start ,flex-start);
  height:auto;
  padding:1em;
  padding-top:0;
}
.content-full >div{
  width:50%;
}
.content-full >div.wide_form{
  width:80%;
}
// texts formats
.medium-text {
  font-size: 1.5rem;
}
.large-text {
  font-size: 2rem;
}
.small-text {
  font-size: 0.5rem;
}
.text-right{
  text-align: right !important;
}
.text-left{
  text-align: left !important;
}
.text-center{
  text-align: center !important;
  align-self: center !important;
}
.text-primary{
  color: $primaryColor;
}
// links
a,
a:visited,
a.nav-item,
a.nav-item:visited {
  color: $secondaryColor;
  text-decoration: none;
}
a:hover,
a.nav-item:hover {
  font-weight: bold;
  color: $secondaryColor;
}
.dashboard,.box {
  @include setBg($defaultColor);
  margin: -6em auto;
  @include shade();
  width: 95%;
  min-height: calc(90vh - 4em);
  height:auto;
  padding: 1em;
  @include flex(row, space-evenly, center);
  flex-wrap: wrap;
}
// .dashboard :has( > span){
//   @include flex(row, space-evenly, flex-start);
// }
.box{
  @include flex(column, space-evenly, center);
  flex-wrap: wrap;
}
.product-details{
  border-left:1px solid $secondaryColor;
  font-size: medium;
}
.product-details > div{
  @include flex(column,flex-start,flex-start);
  margin-bottom: 1em;
}
.product-details label,.form-group > label{
  color: $faintDark;
  text-align: left !important;
}
.product-details span{
  color: $secondaryColor;
  font-size: large;
}
.details,.details-list{
  flex-wrap: wrap;
}
.item-list-head,.item-list{
  // @include setBg($primaryColorTrans);
  width: 100%;
  padding:0.5em 0;
  margin-top: 0.5em;
  font-size: 0.75rem;
  @include flex(row,space-between, space-between);
  min-height: fit-content;
  // height:60px;
  max-height:100px;
}
.item-list-head >span,.item-list >div{
  border-right:.5px solid #b3b8b3bd;
  align-self: center;
  height:max-content;
  // display: block;
  padding:0 .5em;
}
.item-list-head>span:last-child,.item-list>div:last-child{
  border-right:unset;
}
.item-list-head{
  border-left:3px solid transparent;
}
.item-list{
  cursor: pointer;
  @include shade();
}
.flag-red{
  border-left: 3px solid $dangerColor !important;
  // background-color: #045e13 !important;
}
.flag-ok{
  border-left: 3px solid $secondaryColor;
}
.item-list:hover{
  @include setBg($faint);
}
.item-data{
  @include flex(column,center,flex-start);
  margin-left:0.5em;
  flex-grow: 1;
}
.item-image{
  width:50px;
  height:50px;
  border-radius:50%;
  margin:0.5em;
  object-fit: cover;
}
.avatar{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin:0.5em;
 object-fit: cover;
}
.item-title{
  // color:$faint;
  font-size:1.2rem;
}
.item-focus{
  color:$secondaryColor;
}
.details {
  width:calc(50% - 1em);
  // flex-direction: row;
  display: grid;
  // justify-content: flex-start !important;
  // align-items: flex-start;
  // @include flex(row, flex-start, center);
  grid-template-columns: repeat(auto-fill,minmax(25%, 1fr));
  margin:.5em;
}
@media only screen and (max-width: 920px){
  .details {
    width:calc(50% - 1em);
    // flex-direction: row;
    display: grid;
    // justify-content: flex-start !important;
    // align-items: flex-start;
    // @include flex(row, flex-start, center);
    grid-template-columns: repeat(auto-fill,minmax(33%, 1fr));
    margin:.5em;
  }
}
.details-list{
  width: 100%;
  @include flex(row,space-evenly, flex-start);
  align-items:flex-start !important;
}
.actions-wide{
  @include flex(row, space-between, center);
  width:100%;
  // @include setBg($primaryColor);
}
.graph {
  @include flex(column, center, center);
  // background-color: red;
  // border:2px solid blue;
  width: 50% !important;
  // height: 400px !important;
}
.graph > canvas {
  width: 100%;
  height: auto;
}
img.image_viewer{
  display: block;
  margin:2em auto;
  border: 1px solid $primaryColor;
  width:calc(70% - 4em);
  object-fit: contain;
  align-self: center;
}
//message toaster
.toast{
  position:fixed;
  top: 0;
  margin:0 auto;
  background-color:rgba(0, 0, 0, 0.596);
  padding:2em;
  z-index: 9999999999999999999999999999999;
  height:200px;
  display:block;
}

//buttons
.action-buttons{
  @include flex(row,space-evenly,flex-end);
  // border:1px solid red;
  // flex-grow: 1;
}
.action-buttons > span{
  cursor: pointer;
  padding:0.5em;
  // color:$faint;
}
.action-buttons > span:hover{
  color:$secondaryColor;
 background-color: $primaryColorTrans;
 border-radius: 50%;
}

.icon-button {
  border-radius: 5px;
  box-shadow: 3px 3px 3px;
  @include flex(column, center, center);
  padding: .5em;
  color: $primaryColor;
  width: 120px;
  height: 120px;
  margin: 1em auto;
  cursor: pointer;
  border:1px solid $primaryColor;
  flex:0 0 auto;
}
.icon-button > span.material-icons {
  font-size: 3rem;
  cursor: pointer;
}

.icon-button:hover{
  @include setBg($secondaryColor);
  color:$defaultColor;
  border:0px solid $primaryColor;
}

@media only screen and (max-width: 1130px) {
  .icon-button {
    border-radius: 5px;
    box-shadow: 3px 3px 3px;
    @include flex(column, center, center);
    padding: .5em;
    color: $primaryColor;
    width: 100px;
    height: 100px;
    margin: 1em auto;
    cursor: pointer;
    border:1px solid $primaryColor;
    flex:0 0 auto;
  }
  .icon-button > span.material-icons {
    font-size: 2rem;
    cursor: pointer;
  }
  
}
@media only screen and (min-width: 1131px) {
  .icon-button {
    border-radius: 5px;
    box-shadow: 3px 3px 3px;
    @include flex(column, center, center);
    padding: .5em;
    color: $primaryColor;
    width: 116px;
    height: 116px;
    margin: 1em auto;
    cursor: pointer;
    border:1px solid $primaryColor;
    flex:0 0 auto;
  }
  .icon-button > span.material-icons {
    font-size: 3rem;
    cursor: pointer;
  }
  
}
// @media only screen and (min-width: 1115px) {
//   .icon-button {
//     border-radius: 5px;
//     box-shadow: 3px 3px 3px;
//     @include flex(column, center, center);
//     padding: .5em;
//     color: $primaryColor;
//     width: 136px;
//     height: 136px;
//     margin: 1em auto;
//     cursor: pointer;
//     border:1.1px solid $primaryColor;
//     flex:0 0 auto;
//     align-self: flex-start;
//   }
//   .icon-button > span.material-icons {
//     font-size: 3rem;
//     cursor: pointer;
//   }
  
// }
@media only screen and (min-width: 1441px) {
  .icon-button {
    border-radius: 10px;
    box-shadow: 5px 5px 5px;
    @include flex(column, center, center);
    padding: 2em;
    color: $primaryColor;
    width: 150px;
    height: 150px;
    margin: 2em auto;
    cursor: pointer;
    border:2px solid $primaryColor;
    flex:0 0 auto;
    font-size: 1.1rem;
  }
  .icon-button > span.material-icons {
    font-size: 3rem;
    cursor: pointer;
  }
  
}
.bg-success{
  @include setBg($secondaryColor);
  color:$defaultColor !important;
}
.primary-bg{
  @include setBg($primaryColor);
  color:$defaultColor !important;
}
.bg-secondary{
  @include setBg($faintDark);
  color:$defaultColor !important;
}
.feedback,.error{
  border:1px solid white;
  @include setBg($defaultColorTrans);
  text-align: center;
}
.error{
  color: red;
  font-size:small;
}
.danger,.success{
  text-align: center;
  width:100%;
  flex-grow: 3;
  color:$defaultColor;
}
.danger{
  border: 1px solid $dangerColor;
  @include setBg($dangerColorTrans);
}
.success{
  border: 1px solid $secondaryColor;
  @include setBg($secondaryColorTrans);
color:$secondaryColor;
}

//spinner
.spinner{
  display:block;
  width:50px;
  height:50px;
  border-radius: 50%;
  border:2px solid $secondaryColor;
  border-top:none;
  animation: spin 1s linear 0s infinite forwards;
}
.hidden{
  display: none !important;
}
.clickable{
  cursor: pointer;
}
.clickable:hover{
  text-decoration: underline;
}
@keyframes spin{
  from{
    transform:rotate(0deg);
  }
  to{
    transform:rotate(360deg);
  }
}